export default async ({ store, app, $axios }) => {
  const cart = process.browser ? JSON.parse(localStorage.getItem('cart')) : null

  if (typeof cart === 'object' && cart !== null && 'id' in cart) {
    await $axios.get(`${app.i18n.locale}/${store.state.country.iso_code}/cart/${cart.id}`)
      .then((res) => {
        store.dispatch('cart/setCart', res.data.data)
        store.dispatch('cart/setCartCreditProducts')
      })
      .catch((error) => {
        console.log(error)
        localStorage.removeItem('cart')
      })
      .finally(() => store.dispatch('cart/setCartLoading', false))
  } else {
    store.dispatch('cart/setCartLoading', false)
  }

  // Local notification
  // Notification.requestPermission().then((status) => {
  //   if (status === 'granted') {
  //     const send = () => {
  //       // setTimeout(() => {
  //       const notification = new Notification(app.i18n.t('notifications.cart.abandoned.title'), {
  //         icon: app.$config.ogImage,
  //         body: app.i18n.t('notifications.cart.abandoned.body'),
  //         tag: 'abandoned cart'
  //       })

  //       console.log(notification)
  //       // }, 10000)
  //     }

  //     window.addEventListener('onbeforeunload', send, { passive: true })
  //     window.addEventListener('onunload', send, { passive: true })
  //   }
  // })

  // Service worker notification
  // await navigator.serviceWorker.register('/sw.js')

  // function showNotification () {
  //   Notification.requestPermission().then((status) => {
  //     if (status === 'granted') {
  //       console.log(navigator.serviceWorker.ready)
  //       navigator.serviceWorker.ready.then((registration) => {
  //         console.log('1')

  //         console.log(registration)
  //         registration.showNotification(app.i18n.t('notifications.cart.abandoned.title'), {
  //           body: app.i18n.t('notifications.cart.abandoned.body'),
  //           // icon: app.$config.ogImage,
  //           // vibrate: [200, 100, 200, 100, 200, 100, 200],
  //           tag: 'abandoned cart'
  //         })
  //       })
  //     }
  //   })
  // }

  // showNotification()
  // window.addEventListener('onbeforeunload', showNotification, { passive: true })
}
