export default async ({ store, app, $axios }) => {
  const compare = process.browser ? JSON.parse(localStorage.getItem('compare')) : null

  if (typeof compare === 'object' && compare !== null && 'id' in compare) {
    await $axios.get(`${app.i18n.locale}/${store.state.country.iso_code}/compares/${compare.id}`)
      .then((res) => {
        store.dispatch('compare/setCompare', res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
